var App = {
    initUpCTA: function () {
        var top_show = 150; // В каком положении полосы прокрутки начинать показ кнопки "Наверх"
        var delay = 1000; // Задержка прокрутки
        $( document ).ready( function () {
            $( window ).scroll( function () { // При прокрутке попадаем в эту функцию
                /* В зависимости от положения полосы прокрукти и значения top_show, скрываем или открываем кнопку "Наверх" */
                if ( $( this ).scrollTop() > top_show )
                    $( '.button-to-top' ).fadeIn();
                else
                    $( '.button-to-top' ).fadeOut();
            } );
            $( '.button-to-top' ).click( function () { // При клике по кнопке "Наверх" попадаем в эту функцию
                /* Плавная прокрутка наверх */
                $( 'body, html' ).animate( {
                    scrollTop: 0
                }, delay );
            } );
        } );
    },

    initVideo: function () {
        if ( $( '#video-block' ).length > 0 ) {
            var myPlayer = videojs( 'video-block' );
            $( 'body' ).on( 'click', '.play-btn', function () {
                if (myPlayer.paused()) {
                    myPlayer.play();
                }
                else {
                    myPlayer.pause();
                }
            });
        }
    },

    initVideotwo: function () {
        if ( $( '#video-block-one' ).length > 0 ) {
            var myPlayer = videojs( 'video-block-one' );
            $( 'body' ).on( 'click', '.play-btn-one', function () {
                if (myPlayer.paused()) {
                    myPlayer.play();
                }
                else {
                    myPlayer.pause();
                }
            });
        }
    },

    modalMinimize: function(){
        $("body").on('click', function(e) {
           if($(e.target).parents('.openvideo').length <= 0 && !$(e.target).hasClass('openwindow')){
                $('.openvideo').addClass( "minimize" ); 
            }
        });
        $('body').on('click', '.openwindow', function (e) {
            $('.openvideo').removeClass( "minimize" );
        });
    }, 

    modalMinimizetwo: function(){
        $("body").on('click', function(e) {
           if($(e.target).parents('.openvideo-two').length <= 0 && !$(e.target).hasClass('openwindow-two')){
                $('.openvideo-two').addClass( "minimize-two" ); 
            }
        });
        $('body').on('click', '.openwindow-two', function (e) {
            $('.openvideo-two').removeClass( "minimize-two" );
        });
    }, 

    modalHide: function(){
        $('body').on('click', '.continue-btn.btn.btn-primary.hidemod', function (e) {
            $('.callback-request').modal('hide');
        });
    },

    modalPause: function(){
        $('body').on('hidden.bs.modal', '.video-1', function (e) {
           var myPlayer = videojs( 'video-block-1' );
           if (myPlayer.play()) {
                myPlayer.pause();
            }
        });
         $('body').on('hidden.bs.modal', '.video-2', function (e) {
           var myPlayer = videojs( 'video-block-2' );
           if (myPlayer.play()) {
                myPlayer.pause();
            }
        });
         $('body').on('hidden.bs.modal', '.video-3', function (e) {
           var myPlayer = videojs( 'video-block-3' );
           if (myPlayer.play()) {
                myPlayer.pause();
            }
        });
    },


    initScripts: function () {
        $( '.autocomplete' ).styler( { 
            selectSearch: 'true',  
            selectSearchLimit: '4',
            selectSearchNotFound: 'Not Found',
            selectSearchPlaceholder: 'Search...',
        } ); 
        $( 'input, select' ).styler( {
            selectSearch: 'false',
            selectSearchLimit: '32',
            locale: 'en',
            locales: {
                'en': {
                  filePlaceholder: 'No file selected',
                  fileBrowse: 'Browse...',
                  fileNumber: 'Selected files: %s',
                  selectPlaceholder: 'Select...',
                  selectSearchNotFound: 'No matches found',
                  selectSearchPlaceholder: 'Search...'
                }
            }
        } );
        if ( $( "[data-toggle='tooltip']" ).length > 0 ) {
            $( '[data-toggle="tooltip"]' ).tooltip( {
                html: true
            } );
        }
    },

    accordionClose: function () {
        $('#accordion' ).on('show.bs.collapse', function () {
            $('#accordionTwo .in').collapse('hide');
        });
        $('#accordionTwo' ).on('show.bs.collapse', function () {
            $('#accordion .in').collapse('hide');
        });
    },

    init: function ( ) {
        this.initUpCTA();
        this.initScripts();
        this.modalMinimize();
        this.modalMinimizetwo();
        this.initVideo();
        this.initVideotwo();
        this.accordionClose();
        this.modalHide();
        this.modalPause();
    }
};
jQuery( document ).ready( function ( ) {
    App.init( );
} );
window.jQuery = window.$ = jQuery;